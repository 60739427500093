
import { api } from "@/api/api";
import {
  ApiGetCostDto,
  ApiGetCourseParticipantTravelAndExpenseDto,
  ApiGetExpenseLineDto,
  ApiGetStipendDto,
} from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { formatLocalizedDate, formatLocalizedDateAndTime } from "@/shared/helpers/dateHelpers";
import CourseRefundDisplayFile from "@/pages/kurs/details/CourseRefund/CourseRefundDisplayFile.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import { TravelAndExpenseStatus, TravelAndExpenseType } from "@/shared/enums/courseRefund.enum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { StoreState } from "@/store/store.state.interface";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { validateNotEmpty, validateMaxLength } from "@/shared/helpers/validationHelpers";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";

const travelTypes = [
  TravelAndExpenseType.TravelWithOwnVehicle,
  TravelAndExpenseType.TravelWithOwnVehicleRaisedRate,
  TravelAndExpenseType.PassengerCompensation,
];

export default defineComponent({
  name: "CourseExpenseDetailsPage",
  components: { CourseRefundDisplayFile, BaseSheetField, BaseConfirmModalForm, BaseModal, BaseLayout },
  setup() {
    const mainFormValues = ref<ApiGetCourseParticipantTravelAndExpenseDto>();
    const costTypes = ref<ApiGetCostDto[]>([]);
    const route = useRoute();
    const store = useStore<StoreState>();
    const course = computed(() => store.state.courses.course);
    const expenseId = +route.params.expenseId;
    const waitingForApproval = ref<boolean>(false);
    const rejectExpenseModalData = ref<ModalBaseData>(getInitialModalData());
    const rejectComment = ref<string>("");
    const totalRefundAmount = ref<number>(0);

    onMounted(async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await Promise.all([loadRefundClaim(), getCostTypes()]);
        waitingForApproval.value = mainFormValues.value?.status === TravelAndExpenseStatus.ToApproval;
      });
    });

    const setTotalAmount = async () => {
      if (!mainFormValues.value) {
        return;
      }

      const { expenseLinesFrom, expenseLinesTo, stipend } = mainFormValues.value;

      const totalFrom = expenseLinesFrom?.reduce((prev, curr) => prev + curr.totalAmount, 0) || 0;
      const totalTo = expenseLinesTo?.reduce((prev, curr) => prev + curr.totalAmount, 0) || 0;
      const totalStipend = stipend?.totalAmount || 0;

      totalRefundAmount.value = totalFrom + totalTo + totalStipend;
    };

    const getTypeName = (costId: number) =>
      costTypes.value.find((costType) => costType.id === costId)?.name ?? "Utgift";

    const getQuantityLabel = (costId: number) => {
      const currentCostType = costTypes.value.find((costType) => costType.id === costId);

      if (currentCostType?.travelAndExpenseType === TravelAndExpenseType.DietWithAccomodation) {
        return "Antall overnattinger";
      }

      if (travelTypes.includes(currentCostType?.travelAndExpenseType as TravelAndExpenseType)) {
        return "Distanse";
      }

      return "Antall";
    };

    const showQuantity = (costId: number) => {
      const currentCostType = costTypes.value.find((costType) => costType.id === costId);

      return (
        currentCostType?.travelAndExpenseType !== TravelAndExpenseType.DietBetweenSixAndTwelve &&
        currentCostType?.travelAndExpenseType !== TravelAndExpenseType.DietOverTwelveHours
      );
    };

    const getCostTypes = async () => {
      costTypes.value = (await api.economy.getCostsPerOrganizationAsync({ CostTypeCostGroup: "utgifter" })).data;
    };

    const loadRefundClaim = async () => {
      mainFormValues.value = (await api.travelAndExpense.getCourseParticipantTravelAndExpense(expenseId)).data;

      await setTotalAmount();
    };

    const getAttachmentIds = (expenseLine: ApiGetStipendDto | ApiGetExpenseLineDto) =>
      expenseLine.attachments?.map((attachment) => attachment.id) ?? [];

    const createOrder = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.travelAndExpense.createOrderFromCourseParticipantTravelAndExpense(expenseId);

        await loadRefundClaim();

        openNotification(store, NotificationItemType.Success, "Ordre er opprettet");
      });
    };

    const approve = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.travelAndExpense.approveCourseParticipantTravelAndExpense(expenseId);

        await loadRefundClaim();

        openNotification(store, NotificationItemType.Success, "Refusjonen er godkjent");
      });
    };

    const decline = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.travelAndExpense.rejectCourseParticipantTravelAndExpense(expenseId, { comment: rejectComment.value });
        rejectExpenseModalData.value.showModal = false;

        await loadRefundClaim();

        openNotification(store, NotificationItemType.Information, "Refusjonen er avvist");
      });
    };

    return {
      course,
      mainFormValues,
      getTypeName,
      getAttachmentIds,
      formatLocalizedDate,
      formatLocalizedDateAndTime,
      approve,
      decline,
      createOrder,
      waitingForApproval,
      TravelAndExpenseStatus,
      getQuantityLabel,
      showQuantity,
      rejectExpenseModalData,
      rejectComment,
      validateNotEmpty,
      validateMaxLength,
      openEditCourseCertificate: useOpenModal(ModalType.Unspecified, "Avvis refusjon", rejectExpenseModalData),
      formatCurrency,
      totalRefundAmount,
      navigateToCourseExpenses: () => useNavigateBack(SingleCourseRouteNames.CourseExpenses),
    };
  },
});
