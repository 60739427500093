var render = function () {
  var _vm$mainFormValues$ex, _vm$mainFormValues, _vm$mainFormValues2, _vm$mainFormValues2$e, _vm$mainFormValues3, _vm$mainFormValues4, _vm$mainFormValues5, _vm$mainFormValues5$s, _vm$mainFormValues6, _vm$mainFormValues6$s, _vm$mainFormValues7, _vm$mainFormValues7$s, _vm$mainFormValues8, _vm$mainFormValues8$s, _vm$mainFormValues9, _vm$mainFormValues9$s, _vm$mainFormValues10, _vm$mainFormValues11, _vm$mainFormValues11$, _vm$mainFormValues12, _vm$mainFormValues13;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.mainFormValues ? _c('div', {
    staticClass: "pa-4 mt-3"
  }, [_vm.rejectExpenseModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        _vm.rejectExpenseModalData.showModal = false;
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.rejectExpenseModalData,
      "disableSubmit": !_vm.rejectComment
    },
    on: {
      "close": function close($event) {
        _vm.rejectExpenseModalData.showModal = false;
      },
      "submit": _vm.decline
    }
  }, [_vm._v(" Er du sikker på at du vil avvise dette refusjonskravet? "), _c('v-textarea', {
    staticClass: "mt-3",
    attrs: {
      "rows": "2",
      "data-cy": "comment",
      "label": "Kommentar",
      "counter": "1000",
      "rules": [].concat(_vm.validateNotEmpty, [_vm.validateMaxLength(1000, 'Kommentar')]),
      "required": ""
    },
    model: {
      value: _vm.rejectComment,
      callback: function callback($$v) {
        _vm.rejectComment = $$v;
      },
      expression: "rejectComment"
    }
  })], 1)], 1) : _vm._e(), _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseExpenses
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }], null, false, 2802678468)
  }, [[_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("Generell informasjon")]), _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [_vm.mainFormValues.userFullName ? _c('BaseSheetField', {
    attrs: {
      "value": _vm.mainFormValues.userFullName,
      "label": "Deltaker navn"
    }
  }) : _vm._e(), _vm.mainFormValues.bankAccountNo ? _c('BaseSheetField', {
    attrs: {
      "value": _vm.mainFormValues.bankAccountNo,
      "label": "Bankkontonummer"
    }
  }) : _vm._e(), _vm.totalRefundAmout ? _c('BaseSheetField', {
    attrs: {
      "value": _vm.formatCurrency(_vm.totalRefundAmount),
      "label": "Totalbeløp til utbetaling"
    }
  }) : _vm._e(), _vm.mainFormValues.description ? _c('BaseSheetField', {
    attrs: {
      "value": _vm.mainFormValues.description,
      "label": "Kommentar"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-row', [(_vm$mainFormValues$ex = _vm.mainFormValues.expenseLinesTo) !== null && _vm$mainFormValues$ex !== void 0 && _vm$mainFormValues$ex.length ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Reise til")]), _vm._l((_vm$mainFormValues = _vm.mainFormValues) === null || _vm$mainFormValues === void 0 ? void 0 : _vm$mainFormValues.expenseLinesTo, function (expenseLine, i) {
    var _expenseLine$origin, _expenseLine$origin2, _expenseLine$origin3, _expenseLine$origin4, _expenseLine$origin5, _expenseLine$origin6, _expenseLine$destinat, _expenseLine$destinat2, _expenseLine$destinat3, _expenseLine$destinat4, _expenseLine$destinat5, _expenseLine$destinat6;

    return _c('v-card', {
      key: expenseLine.costId + i,
      staticClass: "pa-4 mb-4",
      attrs: {
        "elevation": "2",
        "outlined": ""
      }
    }, [_c('b', [_c('BaseSheetField', {
      attrs: {
        "label": "Utgiftstype",
        "value": _vm.getTypeName(expenseLine.costId)
      }
    })], 1), expenseLine.description ? _c('BaseSheetField', {
      attrs: {
        "label": "Kommentar",
        "value": expenseLine.description
      }
    }) : _vm._e(), expenseLine.amount ? _c('BaseSheetField', {
      attrs: {
        "label": "Sats",
        "value": _vm.formatCurrency(expenseLine.amount)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin = expenseLine.origin) !== null && _expenseLine$origin !== void 0 && _expenseLine$origin.addressLine ? _c('BaseSheetField', {
      attrs: {
        "label": "Fra addresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin2 = expenseLine.origin) === null || _expenseLine$origin2 === void 0 ? void 0 : _expenseLine$origin2.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin3 = expenseLine.origin) !== null && _expenseLine$origin3 !== void 0 && _expenseLine$origin3.postalCode ? _c('BaseSheetField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin4 = expenseLine.origin) === null || _expenseLine$origin4 === void 0 ? void 0 : _expenseLine$origin4.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin5 = expenseLine.origin) !== null && _expenseLine$origin5 !== void 0 && _expenseLine$origin5.postalArea ? _c('BaseSheetField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin6 = expenseLine.origin) === null || _expenseLine$origin6 === void 0 ? void 0 : _expenseLine$origin6.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat = expenseLine.destination) !== null && _expenseLine$destinat !== void 0 && _expenseLine$destinat.addressLine ? _c('BaseSheetField', {
      attrs: {
        "label": "Til addresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat2 = expenseLine.destination) === null || _expenseLine$destinat2 === void 0 ? void 0 : _expenseLine$destinat2.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat3 = expenseLine.destination) !== null && _expenseLine$destinat3 !== void 0 && _expenseLine$destinat3.postalCode ? _c('BaseSheetField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat4 = expenseLine.destination) === null || _expenseLine$destinat4 === void 0 ? void 0 : _expenseLine$destinat4.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat5 = expenseLine.destination) !== null && _expenseLine$destinat5 !== void 0 && _expenseLine$destinat5.postalArea ? _c('BaseSheetField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat6 = expenseLine.destination) === null || _expenseLine$destinat6 === void 0 ? void 0 : _expenseLine$destinat6.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.quantity && _vm.showQuantity(expenseLine.costId) ? _c('BaseSheetField', {
      attrs: {
        "label": _vm.getQuantityLabel(expenseLine.costId),
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.quantity
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.departure ? _c('BaseSheetField', {
      attrs: {
        "label": "Dato fra",
        "value": _vm.formatLocalizedDateAndTime(expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.departure)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.arrival ? _c('BaseSheetField', {
      attrs: {
        "label": "Dato til",
        "value": _vm.formatLocalizedDateAndTime(expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.arrival)
      }
    }) : _vm._e(), expenseLine.providedBreakfastQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall frokoster",
        "value": expenseLine.providedBreakfastQuantity
      }
    }) : _vm._e(), expenseLine.providedLunchQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall lunsjer",
        "value": expenseLine.providedLunchQuantity
      }
    }) : _vm._e(), expenseLine.providedDinnerQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall middager",
        "value": expenseLine.providedDinnerQuantity
      }
    }) : _vm._e(), _vm._l(_vm.getAttachmentIds(expenseLine), function (attachmentId) {
      return _c('div', {
        key: attachmentId
      }, [_c('CourseRefundDisplayFile', {
        attrs: {
          "fileId": attachmentId
        }
      })], 1);
    })], 2);
  })], 2) : _vm._e(), (_vm$mainFormValues2 = _vm.mainFormValues) !== null && _vm$mainFormValues2 !== void 0 && (_vm$mainFormValues2$e = _vm$mainFormValues2.expenseLinesFrom) !== null && _vm$mainFormValues2$e !== void 0 && _vm$mainFormValues2$e.length ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Reise fra")]), _vm._l((_vm$mainFormValues3 = _vm.mainFormValues) === null || _vm$mainFormValues3 === void 0 ? void 0 : _vm$mainFormValues3.expenseLinesFrom, function (expenseLine, i) {
    var _expenseLine$origin7, _expenseLine$origin8, _expenseLine$origin9, _expenseLine$origin10, _expenseLine$origin11, _expenseLine$origin12, _expenseLine$destinat7, _expenseLine$destinat8, _expenseLine$destinat9, _expenseLine$destinat10, _expenseLine$destinat11, _expenseLine$destinat12;

    return _c('v-card', {
      key: expenseLine.costId + i,
      staticClass: "pa-4 mb-4",
      attrs: {
        "elevation": "2",
        "outlined": ""
      }
    }, [_c('b', [_c('BaseSheetField', {
      attrs: {
        "label": "Utgiftstype",
        "value": _vm.getTypeName(expenseLine.costId)
      }
    })], 1), expenseLine.description ? _c('BaseSheetField', {
      attrs: {
        "label": "Kommentar",
        "value": expenseLine.description
      }
    }) : _vm._e(), expenseLine.amount ? _c('BaseSheetField', {
      attrs: {
        "label": "Sats",
        "value": _vm.formatCurrency(expenseLine.amount)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin7 = expenseLine.origin) !== null && _expenseLine$origin7 !== void 0 && _expenseLine$origin7.addressLine ? _c('BaseSheetField', {
      attrs: {
        "label": "Fra addresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin8 = expenseLine.origin) === null || _expenseLine$origin8 === void 0 ? void 0 : _expenseLine$origin8.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin9 = expenseLine.origin) !== null && _expenseLine$origin9 !== void 0 && _expenseLine$origin9.postalCode ? _c('BaseSheetField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin10 = expenseLine.origin) === null || _expenseLine$origin10 === void 0 ? void 0 : _expenseLine$origin10.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$origin11 = expenseLine.origin) !== null && _expenseLine$origin11 !== void 0 && _expenseLine$origin11.postalArea ? _c('BaseSheetField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$origin12 = expenseLine.origin) === null || _expenseLine$origin12 === void 0 ? void 0 : _expenseLine$origin12.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat7 = expenseLine.destination) !== null && _expenseLine$destinat7 !== void 0 && _expenseLine$destinat7.addressLine ? _c('BaseSheetField', {
      attrs: {
        "label": "Til addresse",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat8 = expenseLine.destination) === null || _expenseLine$destinat8 === void 0 ? void 0 : _expenseLine$destinat8.addressLine
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat9 = expenseLine.destination) !== null && _expenseLine$destinat9 !== void 0 && _expenseLine$destinat9.postalCode ? _c('BaseSheetField', {
      attrs: {
        "label": "Postnummer",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat10 = expenseLine.destination) === null || _expenseLine$destinat10 === void 0 ? void 0 : _expenseLine$destinat10.postalCode
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && (_expenseLine$destinat11 = expenseLine.destination) !== null && _expenseLine$destinat11 !== void 0 && _expenseLine$destinat11.postalArea ? _c('BaseSheetField', {
      attrs: {
        "label": "Poststed",
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : (_expenseLine$destinat12 = expenseLine.destination) === null || _expenseLine$destinat12 === void 0 ? void 0 : _expenseLine$destinat12.postalArea
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.quantity && _vm.showQuantity(expenseLine.costId) ? _c('BaseSheetField', {
      attrs: {
        "label": _vm.getQuantityLabel(expenseLine.costId),
        "value": expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.quantity
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.departure ? _c('BaseSheetField', {
      attrs: {
        "label": "Dato fra",
        "value": _vm.formatLocalizedDateAndTime(expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.departure)
      }
    }) : _vm._e(), expenseLine !== null && expenseLine !== void 0 && expenseLine.arrival ? _c('BaseSheetField', {
      attrs: {
        "label": "Dato til",
        "value": _vm.formatLocalizedDateAndTime(expenseLine === null || expenseLine === void 0 ? void 0 : expenseLine.arrival)
      }
    }) : _vm._e(), expenseLine.providedBreakfastQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall frokoster",
        "value": expenseLine.providedBreakfastQuantity
      }
    }) : _vm._e(), expenseLine.providedLunchQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall lunsjer",
        "value": expenseLine.providedLunchQuantity
      }
    }) : _vm._e(), expenseLine.providedBreakfastQuantity ? _c('BaseSheetField', {
      attrs: {
        "label": "Antall middager",
        "value": expenseLine.providedDinnerQuantity
      }
    }) : _vm._e(), _vm._l(_vm.getAttachmentIds(expenseLine), function (attachmentId) {
      return _c('div', {
        key: attachmentId
      }, [_c('CourseRefundDisplayFile', {
        attrs: {
          "fileId": attachmentId
        }
      })], 1);
    })], 2);
  })], 2) : _vm._e()], 1), _c('v-row', [(_vm$mainFormValues4 = _vm.mainFormValues) !== null && _vm$mainFormValues4 !== void 0 && _vm$mainFormValues4.stipend ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "12"
    }
  }, [_c('h2', [_vm._v("Stipend")]), _c('v-card', {
    staticClass: "pa-4 mb-4",
    attrs: {
      "elevation": "2",
      "outlined": ""
    }
  }, [(_vm$mainFormValues5 = _vm.mainFormValues) !== null && _vm$mainFormValues5 !== void 0 && (_vm$mainFormValues5$s = _vm$mainFormValues5.stipend) !== null && _vm$mainFormValues5$s !== void 0 && _vm$mainFormValues5$s.fromDate ? _c('BaseSheetField', {
    attrs: {
      "label": "Dato fra",
      "value": _vm.formatLocalizedDate((_vm$mainFormValues6 = _vm.mainFormValues) === null || _vm$mainFormValues6 === void 0 ? void 0 : (_vm$mainFormValues6$s = _vm$mainFormValues6.stipend) === null || _vm$mainFormValues6$s === void 0 ? void 0 : _vm$mainFormValues6$s.fromDate)
    }
  }) : _vm._e(), (_vm$mainFormValues7 = _vm.mainFormValues) !== null && _vm$mainFormValues7 !== void 0 && (_vm$mainFormValues7$s = _vm$mainFormValues7.stipend) !== null && _vm$mainFormValues7$s !== void 0 && _vm$mainFormValues7$s.toDate ? _c('BaseSheetField', {
    attrs: {
      "label": "Dato til",
      "value": _vm.formatLocalizedDate((_vm$mainFormValues8 = _vm.mainFormValues) === null || _vm$mainFormValues8 === void 0 ? void 0 : (_vm$mainFormValues8$s = _vm$mainFormValues8.stipend) === null || _vm$mainFormValues8$s === void 0 ? void 0 : _vm$mainFormValues8$s.toDate)
    }
  }) : _vm._e(), (_vm$mainFormValues9 = _vm.mainFormValues) !== null && _vm$mainFormValues9 !== void 0 && (_vm$mainFormValues9$s = _vm$mainFormValues9.stipend) !== null && _vm$mainFormValues9$s !== void 0 && _vm$mainFormValues9$s.description ? _c('BaseSheetField', {
    attrs: {
      "label": "Kommentar",
      "value": (_vm$mainFormValues10 = _vm.mainFormValues) === null || _vm$mainFormValues10 === void 0 ? void 0 : _vm$mainFormValues10.stipend.description
    }
  }) : _vm._e(), (_vm$mainFormValues11 = _vm.mainFormValues) !== null && _vm$mainFormValues11 !== void 0 && (_vm$mainFormValues11$ = _vm$mainFormValues11.stipend) !== null && _vm$mainFormValues11$ !== void 0 && _vm$mainFormValues11$.hours ? _c('BaseSheetField', {
    attrs: {
      "label": "Timer",
      "value": (_vm$mainFormValues12 = _vm.mainFormValues) === null || _vm$mainFormValues12 === void 0 ? void 0 : _vm$mainFormValues12.stipend.hours
    }
  }) : _vm._e(), _vm._l(_vm.getAttachmentIds((_vm$mainFormValues13 = _vm.mainFormValues) === null || _vm$mainFormValues13 === void 0 ? void 0 : _vm$mainFormValues13.stipend), function (attachmentId) {
    return _c('div', {
      key: attachmentId
    }, [_c('CourseRefundDisplayFile', {
      attrs: {
        "fileId": attachmentId
      }
    })], 1);
  })], 2)], 1) : _vm._e()], 1), _c('v-row', [_vm.mainFormValues.status === _vm.TravelAndExpenseStatus.ToApproval ? _c('v-col', [_c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.openEditCourseCertificate();
      }
    }
  }, [_vm._v("Avvis")])], 1) : _vm._e(), _c('v-col', [_vm.mainFormValues.status === _vm.TravelAndExpenseStatus.ToApproval ? _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.approve();
      }
    }
  }, [_vm._v(" Godkjenn ")]) : _vm._e(), _vm.mainFormValues.status === _vm.TravelAndExpenseStatus.Approved ? _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createOrder();
      }
    }
  }, [_vm._v(" Opprett ordre ")]) : _vm._e()], 1)], 1)]], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }