
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { binaryApi } from "@/api/api";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";

export default defineComponent({
  name: "CourseRefundDisplayFile",
  components: { BaseSheetField },
  props: {
    fileId: {
      type: Number,
      required: true,
    },
  },
  emits: ["input:change"],
  setup({ fileId }) {
    const file = ref<File>();
    const fileUrl = ref<string>();

    onMounted(async () => {
      if (fileId) {
        await getFile(fileId);
      }
    });

    const getFile = async (id: number) => {
      file.value = (await binaryApi.file.downloadFileAsync(id, { format: "blob" })).data;
      fileUrl.value = URL.createObjectURL(new Blob([file.value], { type: file.value?.type }));
    };

    const getObjectURL = (file: File) => (file ? URL.createObjectURL(file) : "#");

    const getSize = (file: File) => {
      const size = file.size / 1048576;
      return size > 0.5 ? `${size.toFixed(2)} mb` : `${file.size} bytes`;
    };

    return {
      file,
      fileUrl,
      getSize,
      getObjectURL,
    };
  },
});
